// General
import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Ui - Kit
import PageSection from 'ui-kit/page-section/page-section';

// Components
import PageLayout from 'components/layouts/page/page.layout';

const whitelistDomains: string[] = [
    'https://qa-api.birdirx.com',
    'https://dev-api.birdirx.com',
    'https://stag-api.birdirx.com',
    'https://api.birdirx.com'
];

const isValidURL = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

const isDomainWhitelisted = (url: string, whitelist: string[]) => {
    try {
        const origin = new URL(url).origin;
        return whitelist.includes(origin);
    } catch {
        return false;
    }
};

const RedirectionPage = () => {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const redirectUser = async () => {
            const searchParams = new URLSearchParams(location.search);

            const link = searchParams.get('link');
            const email = searchParams.get('email');
            const redirect = searchParams.get('redirect');
            const codeExpiration = searchParams.get('codeexpiration');

            // link pattern validations
            if (!link || !email || !redirect || !codeExpiration || !isValidURL(link)) {
                console.error('Redirection error: Unexpected link pattern.');
                navigate('/');
                return;
            }

            // whitelist validation
            if (!isDomainWhitelisted(link, whitelistDomains)) {
                console.error('Redirection error: domain not included in the whitelist.');
                navigate('/');
                return;
            }

            const redirectUrl = new URL(link);
            redirectUrl.searchParams.append('email', email);
            redirectUrl.searchParams.append('redirect', redirect);
            redirectUrl.searchParams.append('codeexpiration', codeExpiration);

            window.location.href = redirectUrl.toString();
        };

        redirectUser();
    }, [location.search]);

    return (
        <PageLayout
            isShortFooter
            isSecurePage={false}
            isLoggedIn={false}
            metaData={{ nodeTitle: t('Loading redirection...') }}
        >
            <PageSection className="text-center">
                <div className="redirection__container">
                    <h4 className="redirection__text">{t('Loading redirection...')}</h4>
                </div>
            </PageSection>
        </PageLayout>
    );
};

export default RedirectionPage;

export const query = graphql`
    query RedirectionData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
